import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

// [container, breakpoint]
const sizes = {
    sm: ["520px", "576px"],
    md: ["720px", "768px"],
    lg: ["960px", "992px"],
    xl: ["1170px", "1200px"],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1]);
    return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
        brand: {
            blue: "#0071C8",
            darkBlue: "#125F7A",
            green: "#A0CF67",
            darkGreen: "#7BB138",
            orange: "#F9A350",
            darkOrange: "#f89636",
            success: "#2DB200",
            danger: "#B5121B",
            black: "#575757",
            blackAlt: "#3f4041",
            darkestGrey: "#777777",
            darkGrey: "#BABABA",
            grey: "#DADADA",
            lightGrey: "#EEEEEE",
            white: "#FFFFFF",
        },
    },
    fonts: {
        ...theme.fonts,
        heading: "Exo, sans-serif",
        body: "Open Sans, sans-serif",
    },
};

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
};

// Global styles designs: link to the client's style guide if there is one.
// https://xd.adobe.com/view/3c74db9d-e4c7-4a42-bd8c-f4a5f75f6c63-6927/screen/50107c78-970c-4fc1-bc2a-61b6efd573fc/

const listStyles = css`
    ul:not([class^="DayPicker"]) {
        max-width: fit-content;
        margin-bottom: 20px;
        position: relative;
        margin-left: 23px;
        list-style-image: url("/bullet.svg");
        li {
            line-height: 30px;
            padding-left: 14px;
            position: relative;
        }
    }
`;

const headings = css`
    h1 {
        font-family: ${EditorTheme.fonts.heading};
        font-weight: 700;
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 10px;

        @media (max-width: 478px) {
            font-size: 32px;
            line-height: 30px;
        }
    }

    h2 {
        font-family: ${EditorTheme.fonts.heading};
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 15px;

        @media (max-width: 478px) {
            font-size: 22px;
            line-height: 28px;
        }
    }

    h3 {
        font-family: ${EditorTheme.fonts.heading};
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    }

    h4 {
        font-family: ${EditorTheme.fonts.heading};
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    h5 {
        font-family: ${EditorTheme.fonts.heading};
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
    }
`;

const inputs = css`
    input,
    select,
    textarea {
        border-radius: 3px !important;
        border-color: ${EditorTheme.colors.brand.grey} !important;

        &:focus {
            border-color: ${EditorTheme.colors.brand.blue} !important;
            box-shadow: 0 0 0 2px ${EditorTheme.colors.brand.grey} !important;
        }
    }

    label {
        font-weight: bold;
    }
`;

const focusVisible = css`
    :focus:not([class*="focus-visible"]) {
        outline: none !important;
        box-shadow: none !important;
    }
`;

const tables = css`
    .table {
        width: 100%;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;

        th {
            font-weight: 700;
        }
        th,
        td {
            min-width: 2em;
            padding: 0.4em;
            padding-left: 30px;
            text-align: left;
        }
        tr {
            background: white;
        }
        tr:nth-child(odd) {
            background: #eeeeee;
        }
    }
`;

export const GlobalStyles = css`
    body {
        color: ${EditorTheme.colors.brand.black};
        font-size: 16px;
        line-height: 18px;
    }

    ${listStyles}
    ${headings}
    ${inputs}
    ${focusVisible}
    ${tables}
`;
