/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { useConfig } from "@peracto/peracto-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxFull } from "@fortawesome/pro-regular-svg-icons/faBoxFull";
import { Flex, Text, Image, Link, Stack } from "@chakra-ui/core";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, connectHits, Configure } from "react-instantsearch-dom";
import isObjectLike from "lodash/isObjectLike";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";

// Attributes that don't start with 'attributes.*'
const UNPREFIXED_ATTRIBUTES = ["sku"];

let Icon = () => (
  <Flex py={10} justifyContent="center" alignItems="center" w="100%">
    <FontAwesomeIcon icon={faBoxFull} size="6x" />
  </Flex>
);

const ProductCard = ({
  product,
  isEditMode,
  productCardDirection = "column",
  hitsDirection,
  fontColour,
  fontFamily,
  attributes,
  attributeOperator,
  isFirst,
  isLast,
  index,
  fallbackImage,
  ...restProps
}) => {
  const images = product.resources?.filter((r) => r.type === "image");
  const firstImage = images ? sortBy(images, ["displayOrder"])[0] : null;

  return (
    <Stack
      className={`product-card`}
      data-testid={`product-card`}
      {...(productCardDirection === "column" && {
        maxW: {
          base: hitsDirection === "column" ? `100%` : "50%",
          md: `50%`,
          lg: `215px`,
          xl: `268px`,
        },
      })}
      {...(productCardDirection === "row" && {
        w: "100%",
      })}
      {...(productCardDirection === "column" && {
        w: {
          base: hitsDirection === "column" ? `100%` : "50%",
          md: `50%`,
          lg: `215px`,
          xl: `268px`,
        },
        flexBasis: {
          base: hitsDirection === "column" ? `100%` : "50%",
          md: `50%`,
          lg: `215px`,
          xl: `268px`,
        },
      })}
      mb={productCardDirection === "row" ? "30px" : 0}
      flex="1"
      ml={{
        base:
          hitsDirection === "column"
            ? 0
            : index % 2 === 0
            ? "-0.5rem"
            : "0.5rem",
        md: index % 2 === 0 ? "-0.5rem" : "0.5rem",
        lg: hitsDirection === "row" ? (isFirst ? "-0.5rem" : "0.5rem") : 0,
      }}
      mr={{
        base:
          hitsDirection === "column"
            ? 0
            : index % 2 === 0
            ? "0.5rem"
            : "-0.5rem",
        md: index % 2 === 0 ? "0.5rem" : "-0.5rem",
        lg: hitsDirection === "row" ? (isLast ? "-0.5rem" : "0.5rem") : 0,
      }}
      {...restProps}
    >
      <Link
        d="flex"
        flexDirection={productCardDirection}
        href={`/products/${product.slug}`}
        color={fontColour}
        _hover={{ textDecoration: "none" }}
        onClick={(e) => {
          e.preventDefault();
          if (isEditMode) {
            return null;
          } else {
            window.location = `/products/${product.slug}`;
          }
        }}
        {...(productCardDirection === "column" && {
          height: "100%",
        })}
      >
        <Image
          {...(isObjectLike(product.attributes) && {
            alt: product.attributes.product_name,
          })}
          src={firstImage?.location || fallbackImage || ""}
          w="100%"
          flex="1"
          maxW={productCardDirection === "row" ? "50%" : "none"}
          className={`product-card__media`}
          data-testid={`product-card__media`}
          objectFit="contain"
          backgroundColor="white"
          style={{ aspectRatio: "1 / 1" }}
        />
        <Text
          fontWeight={`bold`}
          fontSize={{ base: `14px`, lg: `16px` }}
          lineHeight={`20px`}
          mt={productCardDirection === "column" ? `10px` : 0}
          ml={productCardDirection === "row" ? `10px` : 0}
          fontFamily={fontFamily}
          className={`product-card__name`}
          data-testid={`product-card__name`}
          flex="1"
          color={fontColour}
        >
          {isObjectLike(product.attributes) && product.attributes.product_name}
        </Text>
        <Text
          flex="1"
          ml={productCardDirection === "row" ? `10px` : 0}
          fontFamily={fontFamily}
          display="flex"
          alignItems="flex-end"
          color="brand.black"
          fontWeight="bold"
        >
          <Text as="span" fontSize="16px" lineHeight="10px" mr="7px">
            From
          </Text>
          <Text as="span" fontSize="24px" lineHeight="16px" mr="7px">
            £17.54{" "}
          </Text>
          <Text
            as="span"
            fontSize="10px"
            lineHeight="10px"
            display="flex"
            flexDirection="column"
            fontWeight="500"
            textTransform="uppercase"
          >
            <Text as="span">inc.</Text>
            <Text as="span">vat</Text>
          </Text>
        </Text>
      </Link>
    </Stack>
  );
};

const Hits = (props) => {
  const {
    hits,
    isEditMode,
    productCardDirection,
    hitsDirection,
    fontColour,
    fontFamily,
    fallbackImage,
  } = props;

  const inStorefront = !window?.location?.pathname.includes("/content/edit/");

  return hits.length > 0 ? (
    <>
      {hits.map((hit, index) => (
        <ProductCard
          key={hit?.slug || `hit_${index}`}
          product={hit}
          isEditMode={isEditMode}
          productCardDirection={productCardDirection}
          hitsDirection={hitsDirection}
          fontColour={fontColour}
          fontFamily={fontFamily}
          fallbackImage={fallbackImage}
          index={index}
          isFirst={index === 0}
          isLast={index === hits.length - 1}
        />
      ))}
    </>
  ) : isEditMode || !inStorefront ? (
    <Icon />
  ) : null;
};
const CustomHits = connectHits(Hits);

const ProductsContent = ({
  numberOfProducts = 4,
  attributes = [],
  attributeOperator = "and",
  hitsAlignment = "flex-start",
  hitsDirection = "row",
  productCardDirection = "column",
  fontColour = "#000",
  fontFamily = "body",
  fallbackImage = "",
  isEditMode,
}) => {
  const config = useConfig();
  const algolia = config.get("algolia") || {};

  const { applicationId, searchApiKey, indexName } = algolia;

  const searchClient = algoliasearch(applicationId, searchApiKey);

  const [facetFilters, setFacetFilters] = useState();

  useEffect(() => {
    if (attributes?.length > 0) {
      const categories = attributes?.reduce((acc, { attribute, value }) => {
        const attributeKey = UNPREFIXED_ATTRIBUTES.includes(attribute)
          ? attribute
          : `attributes.${attribute}`;
        if (value.length > 0) acc.push(`${attributeKey}:${value}`);

        return acc;
      }, []);

      setFacetFilters(categories);
    }
  }, [attributes]);

  if (isEmpty(algolia)) {
    console.error(
      "No Algolia configuration defined. Please refer to the Peracto documentation."
    );

    return null;
  }

  return useMemo(
    () => (
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <Configure
          hitsPerPage={numberOfProducts}
          facetFilters={
            facetFilters
              ? attributeOperator === "and"
                ? facetFilters
                : [facetFilters]
              : null
          }
        />

        <CustomHits
          isEditMode={isEditMode}
          productCardDirection={productCardDirection}
          hitsDirection={hitsDirection}
          fontColour={fontColour}
          fontFamily={fontFamily}
          fallbackImage={fallbackImage}
        />
      </InstantSearch>
    ),
    [
      facetFilters,
      numberOfProducts,
      attributeOperator,
      productCardDirection,
      fontColour,
      fontFamily,
      isEditMode,
      hitsAlignment,
      hitsDirection,
      fallbackImage,
    ]
  );
};

const ProductsRenderer = ({ state, ...props }) => {
  const { hitsDirection = "row", hitsAlignment } = state;
  return (
    <Stack
      className="product-card-container"
      flexDirection={hitsDirection}
      flexWrap={{
        base: "wrap",
        lg: "nowrap",
      }}
      justifyContent={hitsDirection === "row" ? hitsAlignment : null}
      alignItems={hitsDirection === "column" ? hitsAlignment : null}
      p={hitsDirection === "row" ? "0.5rem" : 0}
    >
      <ProductsContent {...state} {...props} />
    </Stack>
  );
};

export default ProductsRenderer;
