import styled from 'styled-components'

export const AttributeSetSelect = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 1.25rem;
    margin-bottom: 1.25rem;
    background-color: #fff;
    border: 1px solid ${props => props.theme.admin.colors.greyLight};
    border-radius: 3px;

    .attribute-select--icon {
        flex: 0;
        display: flex;
        justify-content: center;
        padding: 0 2.5rem 0 1.25rem;
    }

    .attribute-select--input {
        flex: 1;
    }

    .list__menu {
        z-index: 100;
    }
`

export const LoadingMessage = styled.div`
    text-align: center;
    height: 0;
    margin: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in;

    &.display-message {
        height: auto;
        margin: 15px 0;
        opacity: 1;
    }
`
