import {
    Carousel,
    Container,
    Accordion,
} from "@peracto/peracto-blocks";

import Products from './ThorProducts'

export default {
    plugins: {
        content: [
            Products,
            Carousel,
            Accordion,
        ],
        layout: [Container],
    },
};
