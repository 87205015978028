import React, { useState } from "react";
import Sortable from "react-sortablejs";
import { useParams } from "react-router-dom";
import { Field, FieldArray, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faGripLines } from "@fortawesome/pro-regular-svg-icons/faGripLines";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";

import { SortableRow, Input, Select, Modal } from "@peracto/peracto-ui";
import isEmpty from "lodash/isEmpty";

const ProductOptions = ({ setRedirect, onSubmit, disabled }) => {
  const [showModal, setShowModal] = useState();

  const { id } = useParams();
  const handleManageVariants = async (form) => {
    if (form.dirty) {
      setShowModal(true);
    } else {
      setRedirect(`/products/${id}/variants`);
    }
  };

  return (
    <Field name={`options`}>
      {({ field, form }) => {
        return (
          <>
            <FieldArray name={field.name}>
              {({ push, remove }) => (
                <>
                  <div className="form-group">
                    <div className="d-flex justify-content-between">
                      <label className="mt-1 mb-3">
                        Product Options
                        <span
                          data-tooltip="Product Variants can be configured by the Options created."
                          className="tooltip-wrap ml-1"
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                      </label>

                      {!!id && field?.value?.length > 0 && (
                        <div
                          className="mb-3"
                          data-testid={`button-manage-variants`}
                        >
                          <button
                            className="btn btn-sm btn-outline-primary"
                            disabled={disabled}
                            onClick={(e) => {
                              e.preventDefault();
                              if (!disabled) {
                                handleManageVariants(form);
                              }
                            }}
                          >
                            Manage Variants
                          </button>
                        </div>
                      )}
                    </div>

                    {field?.value?.length > 0 ? (
                      <>
                        <table className="table table-sm">
                          <colgroup>
                            <col width="10%" />
                            <col width="25%" />
                            <col width="25%" />
                            <col width="30%" />
                            <col width="10%" />
                          </colgroup>

                          <thead>
                            <tr>
                              <th></th>
                              <th>Option Label</th>
                              <th>Identifier</th>
                              <th>Option Display</th>
                              <th className="text-right">Remove</th>
                            </tr>
                          </thead>

                          <Sortable
                            tag="tbody"
                            onChange={(order, sortable, evt) => {
                              const values = [...field.value];
                              const movedItem = values[evt.oldIndex];
                              const to = evt.newIndex;
                              values.splice(evt.oldIndex, 1);
                              values.splice(to, 0, movedItem);
                              form.setFieldValue(`${field.name}`, values);
                            }}
                            options={{
                              animation: 100,
                              handle: ".handle",
                            }}
                          >
                            {field.value.map((item, index) => (
                              <SortableRow
                                key={`product-option-${index}`}
                                data-id={`product-option-${index}`}
                                data-testid={`${field.name}_item_${index}`}
                              >
                                <td>
                                  <span
                                    className="handle"
                                    data-testid={`${field.name}_item_${index}_slider-handle`}
                                  >
                                    <FontAwesomeIcon icon={faGripLines} />
                                  </span>
                                </td>
                                <td
                                  data-testid={`${field.name}_item_${index}_label`}
                                  className="align-top"
                                >
                                  <Input
                                    name={`${field.name}.${index}.name`}
                                    type="text"
                                    min="1"
                                    label=""
                                    placeholder=""
                                    disabled={disabled}
                                    onBlur={() => {
                                      if (
                                        field.value.length > 0 &&
                                        !!form.values[field.name][index].key ===
                                          false
                                      ) {
                                        const slug = encodeURI(
                                          field.value[index].name
                                            .split(" ")
                                            .join("-")
                                            .replace(/-+/g, "-")
                                            .toLowerCase()
                                        );

                                        form.setFieldValue(
                                          `${field.name}.${index}.key`,
                                          slug
                                        );
                                      }
                                    }}
                                  />
                                </td>
                                <td
                                  data-testid={`${field.name}_item_${index}_identifier`}
                                  className="align-top"
                                >
                                  <Input
                                    name={`${field.name}.${index}.key`}
                                    type="text"
                                    min="1"
                                    label=""
                                    placeholder=""
                                    disabled={disabled}
                                  />

                                  <ErrorMessage
                                    name={`${field.name}`}
                                    render={(error) => {
                                      if (error?.[index]?.product) {
                                        return (
                                          <div className="form-group has-error">
                                            <p className="error-text text-danger font-italic mt-2">
                                              {error[index].product}
                                            </p>
                                          </div>
                                        );
                                      } else {
                                        return null;
                                      }
                                    }}
                                  />
                                </td>
                                <td
                                  data-testid={`${field.name}_item_${index}_display_type`}
                                  className="align-top"
                                >
                                  <Select
                                    name={`${field.name}.${index}.type`}
                                    label=""
                                    placeholder=""
                                    disabled={disabled}
                                    data-testid={`${field.name}`}
                                    options={[
                                      {
                                        label: "Dropdown / Select",
                                        value: "select",
                                      },
                                      {
                                        label: "Lozenge",
                                        value: "lozenge",
                                      },
                                      {
                                        label: "Swatch",
                                        value: "swatch",
                                      },
                                    ]}
                                  />
                                </td>
                                <td className="text-right">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    disabled={disabled}
                                    onClick={() => {
                                      if (!disabled) {
                                        remove(index);
                                      }
                                    }}
                                    data-testid={`remove-option-entry-${index}`}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </button>
                                </td>
                              </SortableRow>
                            ))}
                          </Sortable>
                        </table>
                      </>
                    ) : (
                      <p className="text-center mt-4 mb-0">
                        This Product has no Variants.
                      </p>
                    )}
                    <div className="d-flex align-items-center justify-content-end mt-4">
                      <button
                        className="btn btn-primary"
                        disabled={disabled}
                        onClick={() => {
                          if (!disabled) {
                            push({
                              key: "",
                              name: "",
                              type: "select",
                              displayOrder: field?.value?.length + 1 || 0,
                            });
                          }
                        }}
                        data-testid="add-variant-option"
                      >
                        <FontAwesomeIcon icon={faPlus} /> Add new Option
                      </button>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>

            <Modal
              isVisible={showModal}
              title="Showing Data"
              close={() => setShowModal(false)}
              buttons={[
                {
                  type: "btn-outline-secondary",
                  text: "Cancel",
                  action: () => {
                    setShowModal(false);
                  },
                },
                {
                  type: "btn-success",
                  text: "Save",
                  action: async () => {
                    const { values, ...actions } = form;

                    onSubmit(values, actions, `/products/${id}/variants`);

                    if (
                      !form.isValidating &&
                      !form.isSubmitting &&
                      !isEmpty(form.errors)
                    ) {
                      setShowModal(false);
                    }
                  },
                },
              ]}
            >
              <FontAwesomeIcon
                icon={faQuestionCircle}
                size="4x"
                className="d-block mb-4"
              />
              Would you like to save the current changes to the form before
              continuing?
            </Modal>
          </>
        );
      }}
    </Field>
  );
};

export default ProductOptions;
