import { lazyLoad } from '@react-page/core'

import createPlugin from './createPlugin'

import ProductsRenderer from './Renderer/ProductsRenderer'

const ProductsForm = lazyLoad(() => import('./Form/ProductsForm'))

const productsPlugin = settings =>
    createPlugin({
        Renderer: ProductsRenderer,
        Controls: ProductsForm,
        ...settings,
    })

const products = productsPlugin()
export default products

export { productsPlugin }
