import styled from 'styled-components'

export const SearchEnginePreview = styled.div`
    font-family: arial, sans-serif;
`

export const SearchEngineUrl = styled.a`
    display: flex;
    align-items: center;
`

export const SearchEngineTitle = styled.a`
    display: block;
    margin: 0.3rem 0;
    font-size: 1rem;
    font-weight: 600;
`

export const SearchEngineDescription = styled.p`
    margin-bottom: 0;
    font-size: 0.8rem;
    color: #999;
`
